<template>
  <div>
    <div v-if="editorProp">
      <button
        class="purple-btn"
        @click="
          editorProp
            .chain()
            .focus()
            .toggleBold()
            .run()
        "
        :class="{ 'is-active': editorProp.isActive('bold') }"
      >
        bold
      </button>
      <button
        class="purple-btn"
        @click="
          editorProp
            .chain()
            .focus()
            .toggleItalic()
            .run()
        "
        :class="{ 'is-active': editorProp.isActive('italic') }"
      >
        italic
      </button>
      <button
        class="purple-btn"
        @click="
          editorProp
            .chain()
            .focus()
            .toggleStrike()
            .run()
        "
        :class="{ 'is-active': editorProp.isActive('strike') }"
      >
        strike
      </button>
      <button
        class="purple-btn"
        @click="
          editorProp
            .chain()
            .focus()
            .toggleBulletList()
            .run()
        "
        :class="{ 'is-active': editorProp.isActive('bulletList') }"
      >
        bullet list
      </button>
      <button class="purple-btn" @click="toggleLink">
        link
      </button>
      <!-- <button @click="editor.chain().focus().undo().run()">
        undo
      </button>
      <button @click="editor.chain().focus().redo().run()">
        redo
      </button> -->
    </div>
    <editor-content
      style=""
      :editor="editorProp"
      :id="numberProp === 1 ? 'rich-editor' : 'rich-editor2'"
    />
  </div>
</template>

<script>
import { EditorContent } from "@tiptap/vue-2";
//import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },
  props: {
    contentProp: String,
    numberProp: Number,
    editorProp: Object,
  },
  methods: {
    toggleLink() {
      let url = window.prompt("URL");
      if(!url.includes('http')) {
        url = 'https://' + url;
      }
      if (url) {
        this.editorProp
          .chain()
          .focus()
          .setLink({ href: url })
          .run();
      }
    }
  },
  data() {
    return {
      editor: this.editorProp,
    };
  },

  // mounted() {
  //   this.editor = new Editor({
  //     extensions: [
  //       StarterKit,
  //     ],
  //     content: this.contentProp,
  //   })
  // },

  beforeDestroy() {
    if (this.editor) {
      this.editorProp.destroy();
    }
  },
};
</script>

<style>

.ProseMirror {
  height: 300px;
  border: 1px solid #6f42c1;
  overflow-y: auto;
}
ul,
ol {
  padding: 0 1rem;
  margin-left: 20px;
}
p {
  margin-left: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  margin-left: 20px;
}

code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0d0d0d, 0.1);
}

hr {
  border: none;
  border-top: 2px solid rgba(#0d0d0d, 0.1);
  margin: 2rem 0;
}
</style>
